// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';

import NodeBody from '~plugins/prismic/components/NodeBody';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import StoryHeader from '~components/StoryHeader';
import useAllNodeBodyComponents from '~hooks/useAllNodeBodyComponents';
import type { PrismicTravel } from '~schema';

export type ClassKey = 'root' | 'bodySection';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicTravel>>,
};

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& > div:nth-child(1) + div[class*="PlainTextSlice-root"]': {
      paddingTop: theme.spacing(7),
    },
  },
  bodySection: {},
});

const nodeBodyComponents = useAllNodeBodyComponents();

const TravelDetailPage = ({ classes, node }: Props) => {
  return (
    <div className={classes.root}>
      <StoryHeader
        title={node?.data?.title?.text}
        subtitle={node?.data?.subtitle}
        date={node?.data?.date}
        backgroundImage={node?.data?.background_image?.url}
        backgroundVideo={node?.data?.background_video?.url}
      />
      <NodeBody
        node={node}
        components={nodeBodyComponents}
        className={classes.bodySection}
      />
    </div>
  );
};

export default compose(
  withNodePage<PrismicTravel, *>({
    getNode: data => data?.prismicTravel,
  }),
  withStyles<ClassKey, *, Props>(styles),
)(TravelDetailPage);

export const query = graphql`
  query TravelDetailPageQuery($prismicId: ID) {
    prismicTravel(prismicId: { eq: $prismicId }) {
      id
      lang
      uid
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        title {
          text
          html
        }
        subtitle {
          text
          html
        }
        background_image {
          alt
          url
          fluid(maxWidth: 1800) {
            ...GatsbyPrismicImageFluid
          }
        }
        background_video {
          link_type
          target
          url
        }
        date
        travel_tag {
          text
          html
        }
        body {
          ... on Node {
            id
          }
          ... on PrismicSliceType {
            slice_type
          }
          ... on PrismicTravelBodyPlainText {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_text {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              slice_text_alignment
              slice_text_justification
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicTravelBodyRearrangeableBlock {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              slice_image {
                alt
                url
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_image_position
              slice_background_color
              slice_padding_top
              slice_padding_bottom
              slice_width
            }
          }
          ... on PrismicTravelBodyMediaGrid {
            id
            slice_type
            primary {
              slice_caption {
                text
                html
              }
              slice_width
              slice_padding_top
              slice_padding_bottom
            }
            items {
              item_media_width
              item_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_video {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicTravelBodyQuote {
            id
            slice_type
            primary {
              slice_headline {
                text
                html
              }
              slice_quote {
                text
                html
              }
              slice_image {
                alt
                url
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicTravelBodyParallaxBanner {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              slice_background_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_text_alignment
              slice_width
            }
          }
          ... on PrismicTravelBodySingleImage {
            id
            slice_type
            primary {
              slice_caption {
                text
                html
              }
              slice_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_width
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicTravelBodyVideo {
            id
            slice_type
            primary {
              slice_caption {
                text
                html
              }
              slice_video {
                link_type
                target
                url
              }
              slice_video_image_caption {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_width
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicTravelBodyContentGrid {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_width
              slice_background_color
              slice_padding_top
              slice_padding_bottom
              items_per_row
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_link_label {
                text
              }
              item_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              item_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicTravelBodyQuote {
            id
            slice_type
            primary {
              slice_headline {
                text
                html
              }
              slice_quote {
                text
                html
              }
              slice_image {
                alt
                url
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicTravelBodyHighlights {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
              slice_padding_top
              slice_padding_bottom
              items_per_row
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_image {
                alt
                url
                fluid(maxWidth: 100) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicTravelBodyFaqs {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_padding_top
              slice_padding_bottom
            }
            items {
              item_question {
                text
                html
              }
              item_answer {
                text
                html
              }
            }
          }
          ... on PrismicTravelBodyEvent {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              slice_image {
                alt
                url
                fluid(maxWidth: 700) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_date_picker
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicTravelBodyCardsSlider {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
              slice_padding_top
              slice_padding_bottom
            }
            items {
              item_title {
                text
                html
              }
              item_subtitle {
                text
                html
              }
              item_description {
                text
                html
              }
              item_image {
                alt
                url
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicTravelBodyRates {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
              slice_padding_top
              slice_padding_bottom
              slice_display
            }
            items {
              item_price {
                text
                html
              }
              item_title {
                text
                html
              }
              item_subtitle {
                text
                html
              }
              item_description {
                text
                html
              }
              item_link_label {
                text
              }
              item_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              item_image {
                alt
                url
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicTravelBodyFullwidthCarousel {
            id
            slice_type
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_link_label {
                text
              }
              item_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              item_background_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_text_alignment
            }
          }
          ... on PrismicTravelBodyEmbeddedContent {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_text {
                text
                html
              }
              slice_embedded_content {
                text
                html
              }
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicTravelBodyTextInColumns {
            id
            slice_type
            primary {
              slice_padding_top
              slice_padding_bottom
            }
            items {
              item_title {
                text
                html
              }
              item_text {
                text
                html
              }
            }
          }
          ... on PrismicTravelBodyCallToAction {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_link_label {
                text
                html
              }
              slice_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              slice_padding_top
              slice_padding_bottom
            }
          }
        }
      }
    }
  }
`;
